import React from "react";
import "./Home.css";
import { Outlet} from "react-router-dom";
import Footer from "../Footer/Footer";
import { useState, useEffect } from "react";
import { getHomePageLabels } from "../../assets/data/data";
import Header from "../Headers/Header";
import HeaderMobile from "../Headers/HeaderMobile";

const Home = ({ isEnglish, changeLanguage }) => {
  const [labels, setLabels] = useState({});
  const [load, setLoad] = useState(false);
  // const [promoRendered,setpromoRendered]=useState(false);
  useEffect(() => {
    setLabels(getHomePageLabels(isEnglish));
    setLoad(true);
  }, [isEnglish]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     var modal = document.getElementById("myModal");
  //     modal.style.display = "block";
  //     var span = document.getElementById("close");
  //     var body=document.getElementsByTagName("body")[0];
  //     body.style.overflow="hidden";
  //     span.onclick = function () {
  //       modal.style.display = "none";
  //       body.style.overflow="scroll";
  //     }
  //   }, 4000)
  // }, []);
  if (load) {
    return (
      <div className="header-container w-full">
        <Header data={labels}></Header>
        <HeaderMobile data={labels}></HeaderMobile>
        <Outlet />
        <Footer isEnglish={isEnglish} changeLanguage={changeLanguage} />
        {/* <div id="myModal" class="modal">
          <div class="modal-content relative uppercase font-Akatab">
            <span id="close" className="absolute right-2 top-0 ">&times;</span>
            <p className="sm:py-10 lg:py-5 px-2 text-center"><strong>{isEnglish ? "Eyebrows Threading Only $7" : "Enfiletage des sourcils seulement $7"}</strong></p>
          </div>
        </div> */}
      </div>
    );
  } else {
    <div>loading</div>
  }
};

export default Home;
