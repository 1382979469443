import React from 'react';

const TestimonialCard = ({ data }) => {




  return (
    <div className="font-sans border shadow bg-white max-w-96 w-4/5 my-2 mx-2  box-border rounded-lg">
      <div className="testimonial-comments text-sm text-slate-500 mt-5 box-border min-h-[150px] px-6 flex flex-col gap-3 align-center tracking-wider leading-6">
        <div className="testimonial-raiting flex items-start justify-start">
          { }
          <svg
            className="h-5 w-5 "
            viewBox="0 0 24 24"
            fill="#ffa943"
            stroke="#ffa943"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            {" "}
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
          <svg
            className="h-5 w-5 "
            viewBox="0 0 24 24"
            fill="#ffa943"
            stroke="#ffa943"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            {" "}
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
          <svg
            className="h-5 w-5 "
            viewBox="0 0 24 24"
            fill="#ffa943"
            stroke="#ffa943"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            {" "}
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
          <svg
            className="h-5 w-5 "
            viewBox="0 0 24 24"
            fill="#ffa943"
            stroke="#ffa943"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            {" "}
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
          <svg
            className="h-5 w-5 "
            viewBox="0 0 24 24"
            fill="#ffa943"
            stroke="#ffa943"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            {" "}
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
          </svg>
        </div>
        <q>
          {data.quote}
        </q>

      </div>
      <div className={`${data.bgColor} w-full flex flex-col justify-center items-center rounded`}>
        <div className="testimonial-name font-semibold uppercase text-primary-dark text-sm py-5">
          {data.name}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;