// import IMAGE from './../../assets/images/Promo/Shop1.jpg'

import { useEffect, useState } from 'react';
import { getPageDetails } from '../../assets/data/data';
import { Link } from 'react-router-dom';

const Promo = ({ isEnglish }) => {
    const [promos, setPromos] = useState([]);
    useEffect(() => {
        setPromos(getPageDetails(isEnglish, "promoPage").promos);
    }, [isEnglish])
    return (
        <div className='promo-page flex flex-col sm:gap-y-10 lg:gap-y-20'>
            {promos.map((promo,index) => {
                return (
                    <div key={index} className="promo-card flex flex-col lg:flex-row mt-10">
                        <img src={require("../../assets/images/"+promo.imagePath)} alt='Promo Code' className='sm:w-full object-center object-cover lg:w-1/2 lg:max-w-[500px] lg:pl-12 sm:border-y lg:border-none' loading='lazy'/>
                        <div className="promo-details flex flex-col items-start sm:px-4 sm:pt-8 lg:pl-10">
                            <div className=" font-Aldrich promo-details-heading text-3xl tracking-wider uppercase">
                                {promo.title}
                            </div>
                            <div className="promo-detail-highlights pt-6 tracking-wide sm:leading-8 lg:leading-10 text-light-black text-sm">
                                {promo.detail}
                            </div>
                            <div className='flex justify-center lg:justify-start w-full mt-6 uppercase '>
                                {
                                    promo.isRoute ? 
                                    <Link to={promo.href} className='promo-detail-button gradiant hover:shadow-xl px-4 py-2 text-white text-sm'>{promo.buttonName}</Link> 
                                    :
                                    <a href={promo.buttonHref} className="promo-detail-button gradiant hover:shadow-xl px-4 py-2 text-white text-sm">{promo.buttonName}</a>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>

    )
}

export default Promo;