import React, { useEffect, useState } from "react";
import LOGO from '../../assets/images/Logo.png';
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { IoMdMenu } from "react-icons/io";

const HeaderMobile = ({ data }) => {
    const [isOpen, setOpen] = useState(false);
    const [labels, setLabels] = useState({});

    useEffect(() => {
        setLabels(data);
    }, [data]);

    const onClick = () => {
        setOpen(!isOpen);
    }
    return (
        <div className="md:hidden flex flex-col w-full items-center p-[10px]">
            <div className="flex flex-row  flex-row justify-between w-full items-center" >
                <div className="logo-container h-10 md:h-12 lg:h-20">
                    <img className="h-full" src={LOGO} alt="Brand Logo" />
                </div>
                <div className="font-Aldrich header-brand relative left-3 text-3xl md:text-4xl lg:text-5xl">
                    <Link to={'/'}>HK BEAUTÉ <span className="text-xs">Et spa</span></Link>
                </div>
                <div className="button" onClick={onClick}>
                    {!isOpen ? (<IoMdMenu size={30} />) : (<MdClose size={30} />)}
                </div>
            </div>


            {isOpen ?
                (
                    <div className="flex flex-col font-Aldrich w-full p-[10px] items-end ">
                        <div className="hover:border-black  border-b-2 border-transparent w-fit"><Link to="/" onClick={onClick}>{labels.welcome}</Link></div>
                        <div className="hover:border-black  border-b-2 border-transparent w-fit"><Link to="/services" onClick={onClick}>{labels.ourServices}</Link></div>
                        <div className="hover:border-black  border-b-2 border-transparent w-fit"><Link to="/promo" onClick={onClick}>{labels.promo}</Link></div>
                        <div className="hover:border-black  border-b-2 border-transparent w-fit"><Link to="/about-us" onClick={onClick}>{labels.aboutUs}</Link></div>
                        <div className="hover:border-black  border-b-2 border-transparent w-fit"><Link to="/locate-us" onClick={onClick}>{labels.locateUs}</Link></div>
                    </div>
                )
                : (<></>)}

        </div>
    )
}

export default HeaderMobile;