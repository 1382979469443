import React, { useEffect, useState } from "react";
import {getPageDetails} from '../../assets/data/data';
import ServiceCard from "../ServiceCard/ServiceCard";

const Services = ({ isEnglish }) => {
  const CURRENT_PAGE_NAME='servicesPage';
  const [servicePageData,setServicePageData]=useState(getPageDetails(isEnglish,CURRENT_PAGE_NAME));
  useEffect(()=>{
    setServicePageData(getPageDetails(isEnglish,CURRENT_PAGE_NAME));
  },[isEnglish]);
  return (
    <div id="services" className="services w-full text-black sm:mt-10 lg:mt-20">
      <p className="header font-Architects w-fit mx-auto text-2xl lg:text-4xl text-primary-text tracking-widest uppercase">
        {servicePageData.heading}
      </p>
      <div className="services-container flex flex-wrap  justify-center mt-5 lg:mt-20 sm:mx-[2px] gap-x-5 gap-y-5">
        {servicePageData.services.map((service) => (
          <ServiceCard service={service} isEnglish={isEnglish} key={service.Id}/>
        ))}
      </div>
    </div>
  );
};

export default Services;
