const EN_DATA = {
  homePage: {
    header: {
      brandName: "Hk Beauté",
      welcome: "Welcome",
      ourServices: "Our Services",
      promo:"Promo",
      aboutUs: "About",
      locateUs: "Locate",
    },
    landingPage: {
      header: "Step Into the Spotlight",
      description:
        "Transform your look in an atmosphere of elegance and relaxation. Whether you're here for a new style or a moment of well-being, we combine art and craftsmanship to reveal the best version of yourself.",
      imagePath: "LandingPage/LandingPageImageLg.jpg",
      buttonName: "Check Our Services",
      promoButton:"Unlock exclusive discounts"
    },
  },
  servicesPage: {
    heading: "Our Services",
    services: [
      {
        Id: "1",
        serviceName: "THREADING",
        description: "Smooth Brows, Forehead, Neck & More",
        imagePath: "Services/Threading.jpg",
        subServicePriceList: [
          {
            EYEBROWS: "7$",
          },
          {
            FOREHEAD: "5$",
          },
          {
            LIPS: "5$",
          },
          {
            CHIN: "5$",
          },
          {
            NECK: "5$",
          },
          {
            PATTES: "10$",
          },
          {
            FULLFACE: "30$",
          },
          {
            "EYEBROWS TINTING": "20$",
          },
        ],
      },
      {
        Id: "2",
        serviceName: "WAXING",
        description: "Smooth Skin, Silky Feel",
        imagePath: "Services/Waxing.jpg",
        subServicePriceList: [
          {
            "FULL ARMS": "20$",
          },
          {
            "HALF ARMS": "15$",
          },
          {
            UNDERARMS: "10$",
          },
          {
            SHOULDER: "10$",
          },
          {
            "FULL FRONT": "25$",
          },
          {
            "HALF FRONT": "15$",
          },
          {
            BELLYBUTTON: "8$",
          },
          {
            "FULL BACK": "25$",
          },
          {
            "HALF BACK": "15$",
          },
          {
            "LOWER BACK": "15$",
          },
          {
            BUTTOCKS: "20$",
          },
          {
            BRAZILIENNE: "30$",
          },
          {
            "BIKINI LIGNE": "15$",
          },
          {
            "FULL LEGS": "30$",
          },
          {
            "HALF LEGS": "20$",
          },
          {
            "FULL BODY": "120$",
          },
          {
            "CORPS COMPLET + VISAGE": "140$",
          },
          {
            "FULL BODY + FACE": "140$",
          },
        ],
      },
      {
        Id: "3",
        serviceName: "FACIALS",
        description: "Customized Facials for a Glowing Complexion",
        imagePath: "Services/Facials.jpg",
        subServicePriceList: [
          {
            "D-TAN FACIAL": "70$",
          },
          {
            "GOLD FACIAL": "55$",
          },
          {
            "DIAMOND FACIAL": "65$",
          },
          {
            "GOLD SHEEN FACIAL": "75$",
          },
          {
            "OXYGENATING FACIAL": "🕿",
          },
          {
            DERMABARSION: "🕿",
          },
          {
            "ACNE TREATMENT": "🕿",
          },
          {
            "ANTI AGING TREATMENT": "🕿",
          },
        ],
      },
      {
        Id: "4",
        serviceName: "LASHES",
        description: "Bigger, Bolder, More Beautiful Eyes",
        imagePath: "Services/Lashes.jpg",
        subServicePriceList: [
          {
            "LASH LIFT": "🕿",
          },
          {
            "LASH TINT": "🕿",
          },
          {
            "ACE-SKIN-HAIR": "🕿",
          },
          {
            "EYEBROWS LAMINATION": "🕿",
          },
          {
            "EYEBROWS TINT": "🕿",
          },
        ],
      },
      {
        Id: "5",
        serviceName: "HAIR SERVICES",
        description: "Styles That Turn Heads",
        imagePath: "Services/HairServices.jpg",
        subServicePriceList: [
          {
            "HAIR CUT": "🕿",
          },
          {
            "BLOW DRY": "🕿",
          },
          {
            STYLE: "🕿",
          },
          {
            "GLOBAL COLOR": "🕿",
          },
          {
            HIGHLIGHTS: "🕿",
          },
          {
            HIGHLIGHTS: "🕿",
          },
          {
            BALAYAGE: "🕿",
          },
          {
            HAIRSPA: "🕿",
          },
          {
            "SCALP TREATMENT": "🕿",
          },
          {
            "HEAD MASSAGE": "🕿",
          },
          {
            KERATIN: "🕿",
          },
          {
            NANOPLASTIA: "🕿",
          },
        ],
      },
      {
        Id: "6",
        serviceName: "EYEBROWS",
        description: "Your Brows, Styled to Perfection",
        imagePath: "Services/MakeupEyebrows.jpg",
        subServicePriceList: [
          {
            "EYEBROWS MICROBLADING": "🕿",
          },
          {
            "EYEBROWS SHADING": "🕿",
          },
          {
            "EYEBROWS NANOBROWS": "🕿",
          },
        ],
      },
      {
        Id: "7",
        serviceName: "TREATMENTS",
        description: "Nourishing Treatments for Healthier Skin",
        imagePath: "Services/Microneedling.jpg",
        subServicePriceList: [
          {
            MICRONEEDLING: "🕿",
          },
          {
            "BIO-MICRONEEDLING": "🕿",
          },
          {
            HYDRAFACIAL: "🕿",
          },
        ],
      },
      {
        Id: "8",
        serviceName: "LASER HAIR REMOVAL",
        description: "Permanent Smoothness, Zero Hassle",
        imagePath: "Services/LaserHairRemoval.jpg",
        subServicePriceList: [
          {
            "CALL TO BOOK A FREE CONSULATION": "🕿",
          },
        ],
      },
      {
        Id: "9",
        serviceName: "MAKEUP",
        description: "Glam Up for Every Occasion",
        imagePath: "Services/Makeup.jpg",
        subServicePriceList: [
          {
            "BRIDAL MAKEUP": "🕿",
          },
          {
            "NON BRIDAL MAKEUP": "🕿",
          },
          {
            "BRIDAL HENNA": "🕿",
          },
          {
            "HENNA TATTO": "🕿",
          },
        ],
      },
    ],
  },
  promoPage:{
    promos:[
      {
        title:"Eyebrows Threading Only 7$",
        detail:"At Hk Beauté, we’re all about helping you look and feel your best! For a limited time, enjoy our expert eyebrow threading service for just $7. Our skilled professionals take the time to shape your brows to perfection, ensuring you leave feeling refreshed and confident. We love our customers and strive to provide the best care with every service. Whether you're a regular or it's your first visit, we’re dedicated to giving you the attention and quality service you deserve. Treat yourself to a brow transformation today—because you deserve it!",
        imagePath:"Promo/Image1.jpg",
        buttonName:"Get Directions to Hk Beauté",
        isRoute:true,
        href:"/locate-us"
      },
      {
        title:"Get $5 Off When You Shout Us Out on Instagram!",
        detail:"We love seeing our clients shine! Share a photo of your amazing look after visiting Hk Beauté and tag us in your Instagram story, and we’ll give you $5 off your next service! It’s our way of saying thank you for spreading the love. Whether you’re showing off a new hairstyle, glowing skin, or flawless brows, we want to see it all! Don’t forget to tag us and use our hashtag for a chance to be featured on our page. Your shoutout helps us grow, and we can’t wait to reward you for it!",
        imagePath:"Promo/Instagram.jpg",
        buttonName:"Join the #HkBeauteFam",
        isRoute:false,
        buttonHref:"https://www.instagram.com/beautybyhkstudio/"
      },
      {
        title:"Get 10% Off When You Refer a Friend!",
        detail:"At Hk Beauté, we believe that great experiences are meant to be shared! Invite a friend to discover the care, quality, and style you love at our salon. As a thank-you for spreading the word, both you and your friend will enjoy a 10% discount on your next service. It's more than just a discount—it's our way of showing appreciation to our incredible clients who trust us enough to recommend us to others. When you refer a friend, you’re helping us grow our Hk Beauté family, and that means a lot to us. We’re here to create a warm, welcoming environment for everyone, so why not enjoy it together? Thank you for sharing the beauty and helping us continue to bring style and confidence to the community!",
        imagePath:"Promo/Image2.jpg",
        buttonName:"Get Directions to Hk Beauté",
        isRoute:true,
        href:"/locate-us"
      }
    ]
  },
  aboutPage: {
    heading: "About Us",
    imageName: "AboutPageLarge.jpg",
    description:
      "<strong>Our journey began with a simple mission:</strong> <br /> To bring the highest standards of beauty and grooming to our clients. At <em>Hk Beauté</em>, we believe in enhancing your natural beauty with a personalized approach to hair, skincare, and grooming. Step into our salon and step out feeling confident, refreshed, and ready to take on the world.<br /> <em>Look good, feel great!</em><br />See you!<br /><span class='text-primary-dark font-semibold'>HK BEAUTÉ Et spa</span>",
  },
  locateUs: {
    address: [{
      street: "15 Bd Don-Quichotte Local 103A",
      city:"L'Île-Perrot",
      pincodeAndCountry:"Quebec J7V 7X4",
      contactNumber:"+1 514-425-5999" 
    },{
      street: "30 Rue de Grande-Île",
      city:"Salaberry-de-Valleyfield",
      pincodeAndCountry:"QC J6S 3L8",
      contactNumber:"+1 514-466-4949" 
    },
    null
  ],
    instaURL: "https://www.instagram.com/beautybyhkstudio/",
  },
  footer: {
    instaURL: "https://www.instagram.com/beautybyhkstudio/" ,
    contactNumber: "+1 514-425-5999",
    language: "Language",
    links: "Links",
    contactUs: "Contact",
    aboutUs: "About",
    locateUs: "Locate",
  },
};

const FRN_DATA = {
  homePage: {
    header: {
      brandName: "Hk Beature",
      welcome: "Accueil",
      ourServices: "Nos Soins",
      promo:"Promo",
      aboutUs: "À Propos",
      locateUs: "Localiser",
    },
    landingPage: {
      header: "Entrez sous les projecteurs",
      description:
        "Transformez votre look dans une atmosphère d'élégance et de détente. Que vous soyez ici pour un nouveau style ou un moment de bien-être, nous allions art et savoir-faire pour révéler le meilleur de vous-même.",
      imagePath: "Services/2147601174.jpg",
      buttonName: "Découvrez nos services",
      promoButton:"Réductions exclusives"
    },
  },
  servicesPage: {
    heading: "NOS PRESTATIONS",
    services: [
      {
        Id: "1",
        serviceName: "Enfiletage",
        description: "Sourcils Lisses, Front, Cou et Plus",
        imagePath: "Services/Threading.jpg",
        subServicePriceList: [
          {
            SOURCILS: "7$",
          },
          {
            FRONT: "5$",
          },
          {
            LÈVRES: "5$",
          },
          {
            MENTON: "5$",
          },
          {
            COU: "5$",
          },
          {
            SIDEBURNS: "10$",
          },
          {
            "VISAGE COMPLET": "30$",
          },
          {
            "TEINTURE DES SOURCILS": "20$",
          },
          {
            "EYEBROWS TINTING": "20$",
          },
        ],
      },
      {
        Id: "2",
        serviceName: "ÉPILATION À LA CIRE",
        description: "Peau Douce et Lisse, Sensation Satinée",
        imagePath: "Services/Waxing.jpg",
        subServicePriceList: [
          {
            "BRAS COMPLET": "20$",
          },
          {
            "DEMI BRAS": "15$",
          },
          {
            AISELLES: "10$",
          },
          {
            ÉPAULES: "10$",
          },
          {
            "DEVANT COMPLET": "25$",
          },
          {
            "DEVANT MOITIÉ": "15$",
          },
          {
            NOMBRIL: "8$",
          },
          {
            "DOS COMPLET": "25$",
          },
          {
            "DOS MOITIÉ": "15$",
          },
          {
            "DOS BAS": "15$",
          },
          {
            FESSES: "20$",
          },
          {
            BIKINI: "30$",
          },
          {
            "LIGNE DE BIKINI": "15$",
          },
          {
            "JAMBES COMPLET": "30$",
          },
          {
            "DEMI JAMBES": "20$",
          },
          {
            "CORPS COMPLET": "120$",
          },
          {
            "CORPS COMPLET + VISAGE": "140$",
          },
          {
            "FULL BODY + FACE": "140$",
          },
        ],
      },
      {
        Id: "3",
        serviceName: "FACIALES",
        description: "Soins du Visage Personnalisés pour un Teint Éclatant",
        imagePath: "Services/Facials.jpg",
        subServicePriceList: [
          {
            "D-TAN FACIAL": "70$",
          },
          {
            "GOLD FACIAL": "55$",
          },
          {
            "DIAMOND FACIAL": "65$",
          },
          {
            "GOLD SHEEN FACIAL": "75$",
          },
          {
            "OXYGENATING FACIAL": "🕿",
          },
          {
            DERMABARSION: "🕿",
          },
          {
            "ACNE TREATMENT": "🕿",
          },
          {
            "ANTI AGING TREATMENT": "🕿",
          },
        ],
      },
      {
        Id: "4",
        serviceName: "CILS",
        description: "Des Yeux Plus Grands, Plus Audacieux, Plus Beaux",
        imagePath: "Services/Lashes.jpg",
        subServicePriceList: [
          {
            "LASH LIFT": "🕿",
          },
          {
            "LASH TINT": "🕿",
          },
          {
            "ACE-SKIN-HAIR": "🕿",
          },
          {
            "EYEBROWS LAMINATION": "🕿",
          },
          {
            "EYEBROWS TINT": "🕿",
          },
        ],
      },
      {
        Id: "5",
        serviceName: "CHEVEUX",
        description: "Des Styles Qui Font Tourner les Têtes",
        imagePath: "Services/HairServices.jpg",
        subServicePriceList: [
          {
            "COUPE DE CHEVEUX": "🕿",
          },
          {
            "SÈCHE CHEVEUX": "🕿",
          },
          {
            STYLE: "🕿",
          },
          {
            COULEUR: "🕿",
          },
          {
            HIGHLIGHTS: "🕿",
          },
          {
            BALAYAGE: "🕿",
          },
          {
            "SPA CHEVEUX": "🕿",
          },
          {
            "TRAITEMENT DU CUIR CHEVELU": "🕿",
          },
          {
            "MASSAGE DE TETE": "🕿",
          },
          {
            KERATIN: "🕿",
          },
          {
            NANOPLASTIA: "🕿",
          },
        ],
      },
      {
        Id: "6",
        serviceName: "MAQUILLAGE PERMANENT",
        description: "Vos Sourcils, Stylisés à la Perfection",
        imagePath: "Services/MakeupEyebrows.jpg",
        subServicePriceList: [
          {
            "EYEBROWS MICROBLADING": "🕿",
          },
          {
            "EYEBROWS SHADING": "🕿",
          },
          {
            "EYEBROWS NANOBROWS": "🕿",
          },
        ],
      },
      {
        Id: "7",
        serviceName: "TRAITEMENTS",
        description: "Soins Nourrissants pour une Peau Plus Saine",
        imagePath: "Services/Microneedling.jpg",
        subServicePriceList: [
          {
            MICRONEEDLING: "🕿",
          },
          {
            "BIO-MICRONEEDLING": "🕿",
          },
          {
            HYDRAFACIAL: "🕿",
          },
        ],
      },
      {
        Id: "8",
        serviceName: "Épilation laser",
        description: "Lissage Permanent, Zéro Tracas",
        imagePath: "Services/LaserHairRemoval.jpg",
        subServicePriceList: [
          {
            "APPELEZ POUR RÉSERVER UNE CONSULATION GRATUITE": "🕿",
          },
        ],
      },
      {
        Id: "9",
        serviceName: "MAQUILLAGE",
        description: "Soyez Glamour pour Chaque Occasion",
        imagePath: "Services/Makeup.jpg",
        subServicePriceList: [
          {
            MARRIAGE: "🕿",
          },
          {
            "NON BRIDAL MAKEUP": "🕿",
          },
          {
            HENNA: "🕿",
          },
          {
            "HENNA TATTO": "🕿",
          },
        ],
      },
    ],
  },
  promoPage:{
    promos:[
      {
        title:"Enfiletage des sourcils uniquement $7",
        detail:"Chez Hk Beauté, nous sommes là pour vous aider à vous sentir et à paraître à votre meilleur ! Profitez de notre service expert d'épilation des sourcils pour seulement 7 $ pendant une durée limitée. Nos professionnels qualifiés prennent le temps de sculpter vos sourcils à la perfection, pour que vous repartiez rafraîchi(e) et confiant(e). Nous aimons nos clients et nous efforçons d'offrir le meilleur soin à chaque prestation. Que vous soyez un client fidèle ou que ce soit votre première visite, nous sommes dévoués à vous offrir l'attention et le service de qualité que vous méritez. Offrez-vous une transformation des sourcils dès aujourd'hui—parce que vous le méritez !",
        imagePath:"Promo/Image1.jpg",
        buttonName:"Obtenir l'itinéraire vers Hk Beauté",
        isRoute:true,
        href:"/locate-us"
      },
      {
        title:"Obtenez $5 de réduction en nous mentionnant sur Instagram !",
        detail:"Nous adorons voir nos clients briller ! Partagez une photo de votre look incroyable après votre visite chez Hk Beauté et identifiez-nous dans votre story Instagram, et nous vous offrirons $5 de réduction sur votre prochain service ! C'est notre façon de vous remercier pour votre soutien. Que vous montriez une nouvelle coiffure, une peau éclatante ou des sourcils parfaits, nous voulons tout voir ! N'oubliez pas de nous identifier et d'utiliser notre hashtag pour avoir la chance d'être mis en avant sur notre page. Votre mention nous aide à grandir, et nous avons hâte de vous récompenser pour cela !",
        imagePath:"Promo/Instagram.jpg",
        buttonName:"Rejoignez la #HkBeautéFam",
        isRoute:false,
        buttonHref:"https://www.instagram.com/beautybyhkstudio/"
      },
      {
        title:"Obtenez 10% de réduction en parrainant un ami !",
        detail:"**Chez Hk Beauté, nous croyons que les belles expériences sont faites pour être partagées ! Invitez un(e) ami(e) à découvrir le soin, la qualité et le style que vous aimez dans notre salon. Pour vous remercier de faire connaître notre salon, vous et votre ami(e) bénéficierez d'une réduction de 10% sur votre prochain service. Ce n'est pas qu'une remise—c'est notre façon de montrer notre gratitude à nos incroyables clients qui nous font confiance et nous recommandent. En parrainant un(e) ami(e), vous aidez notre famille Hk Beauté à grandir, et cela signifie beaucoup pour nous. Nous sommes là pour créer un environnement chaleureux et accueillant pour tous, alors pourquoi ne pas en profiter ensemble ? Merci de partager la beauté et de nous aider à continuer à apporter style et confiance à la communauté !**",
        imagePath:"Promo/Image2.jpg",
        buttonName:"Obtenir l'itinéraire vers Hk Beauté",
        isRoute:true,
        href:"/locate-us"
      }
    ]
  },
  aboutPage: {
    heading: "À propos de nous",
    description:
      "<strong>Notre voyage a commencé par une mission simple :</strong> <br />Apporter les plus hauts standards de beauté et de soins à nos clients. Chez <em>Hk Beauté</em>, nous croyons qu'il est important de mettre en valeur votre beauté naturelle avec une approche personnalisée des cheveux, des soins de la peau et du toilettage. Entrez dans notre salon et ressortez confiant, rafraîchi et prêt à affronter le monde.<br /> <em>Regardez bien, sentez-vous bien !</em><br />À bientôt!!<br /><span class='text-primary-dark font-semibold'>HK BEAUTÉ Et spa</span>",
  },
  locateUs: {
    address: [{
      street: "15 Bd Don-Quichotte Local 103A",
      city:"L'Île-Perrot",
      pincodeAndCountry:"Quebec J7V 7X4",
      contactNumber:"+1 514-425-5999" 
    },{
      street: "30 Rue de Grande-Île",
      city:"Salaberry-de-Valleyfield",
      pincodeAndCountry:"QC J6S 3L8",
      contactNumber:"+1 514-466-4949" 
    },
    null
  ],
    instaURL: "https://www.instagram.com/beautybyhkstudio/",
  },
  footer: {
    instaURL:  "https://www.instagram.com/beautybyhkstudio/",
    contactNumber:"+1 514-425-5999",
    language: "Langue",
    links: "Liens",
    contactUs: "Contact",
    aboutUs: "À Propos",
    locateUs: "localiser",
  },
};

const TESTIMONIAL_DATA = {
  headerEN: "Testimonials",
  headerFRE: "Témoignages",
  list: [
    {
      quote:
        "I just left this beautiful and brand new salon and I only have good things to say. Harmeet was on time, very friendly and she did a great job waxing me today. I recommend this place 100%!",
      name: "Priscila Mori",
      bgColor: "transparent",
    },
    {
      quote:
        "Harmeet and Harman are amazing at their work ,The place is really clean and hygienic. They are also very polite and nice and getting an appointment is super easy. I would recommend them.",
      name: "Pallavi Pathak",
      bgColor: "transparent",
    },
    {
      quote:
        "Harman is skillful and so efficient! She's the best I've encountered so far and she really knows her stuff! Go see her, you won't regret it",
      name: "Mary Z",
      bgColor: "transparent",
    },
  ],
};

export const getServiceDetail = (isEnglish, id) => {
  let data;
  let result;
  if (isEnglish) {
    data = EN_DATA;
  } else {
    data = FRN_DATA;
  }

  for (let i = 0; i < data.servicesPage.services.length; i++) {
    if (data.servicesPage.services[i].Id === id) {
      result = {
        title: data.servicesPage.services[i].serviceName,
        description: data.servicesPage.services[i].description,
        subServices: data.servicesPage.services[i].subServicePriceList,
      };
      break;
    }
  }
  return result;
};

// To fetch the content of a particular page
export const getPageDetails = (isEnglish, pageName) => {
  let data = isEnglish ? EN_DATA : FRN_DATA;
  return data[pageName];
};

export const getAboutUsPageDetails = (isEnglish) => {
  let data = isEnglish ? EN_DATA.aboutPage : FRN_DATA.aboutPage;
  return data;
};

export const getHomePageLabels = (isEnglish) => {
  let data = isEnglish ? EN_DATA.homePage.header : FRN_DATA.homePage.header;
  return data;
};

export const getTestimonialDetails = (isEnglish) => {
  let data = {
    header: isEnglish ? TESTIMONIAL_DATA.headerEN : TESTIMONIAL_DATA.headerFRE,
    list: TESTIMONIAL_DATA.list,
  };
  return data;
};
