import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({isEnglish,service }) => {
  return (
    <div className="font-sans service-card sm:w-4/5 md:w-[40%] lg:w-[26%] my-1 border shadow bg-white box-border rounded-lg lg:hover:scale-105 lg:ease-linear lg:duration-200" key={service.Id}>
      <div className="service-card-img-container bg-transparent  w-[100%] aspect-[4/3]">
        <img
          className="service-img object-cover overflow-hidden w-full h-full"
          src={require("../../assets/images/" +
            service.imagePath)}
          alt="Alternate" loading="lazy"
        />
      </div>
      <div className="font-Akatab service-detail flex flex-col justify-stretch p-[20px] grow">
        <div className="text-lg font-bold max-w-52 sm:max-w-60 md:max-w-96 truncate">
          {service.serviceName}
        </div>
        <div className="text-sm text-light-black max-w-52 sm:max-w-60 md:max-w-96 truncate pt-2 text-ellipsis">
          {service.description}
        </div> 
        <div className="flex w-full pt-3">
          <Link to={"/services/" + service.Id} className="w-full">
            <div className="text-xs text-slate-100 py-2 px-3 bg-slate-900 w-full rounded-3xl text-center uppercase">
               {isEnglish ? "Checkout Prices":"Prix ​​de départ"} 
            </div>
          </Link>
        </div>
      </div>
     
    </div>
  );
};

export default ServiceCard;
