import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ServiceDetail.css";
import { getServiceDetail } from "../../assets/data/data";
import ServiceDetailBG from "../../assets/images/ServiceDetailBG.jpg";

const ServiceDetail = ({ isEnglish }) => {
  const { Id } = useParams();
  const [subServices, setSubServices] = useState(null);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const data = getServiceDetail(isEnglish, Id);
    setSubServices(data.subServices);
    setTitle(data.title);
    // setLoad(true);
  }, [Id, isEnglish]);

  const backgroundImageCSS = {
    backgroundImage: `url(${ServiceDetailBG})`,
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  return (
    <>
      <div className="service-detail-container relative min-h-[80vh] pt-[5vh]">
        <div
          className="h-full w-full absolute top-3 left-0 -z-10 opacity-50"
          style={backgroundImageCSS}
        ></div>
        <div className="pt-3 text-2xl max-w-[500px] w-[100%] flex justify-center border-b-2 border-black pb-[2vw] font-sans">
          {title}
        </div>
          <div className="menu-container font-sans w-full pt-[5vh]">
            {subServices?.map((subService, index) => (
              <div
                key={index}
                className="menu-table flex flex-col justify-center items-center"
              >
                {Object.keys(subService).map((key) => (
                  <div
                    key={key}
                    className="row flex justify-between max-w-[500px] min-w-[320px] w-full px-5 py-[1vh]"
                  >
                    <div>{key}</div>
                    <div>{subService[key]}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
      </div>
    </>
  );
};

export default ServiceDetail;
