import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home/Home'
import Services from './components/Services/Services'
import About from './components/About/About'
import LocateUs from './components/LocateUs/LocateUs'
import { useEffect, useState } from 'react';
import ServiceDetail from './components/ServiceDetail/ServiceDetail';
import LandingPage from './components/LandingPage/LandingPage';
import Promo from './components/Promo/Promo';

function App() {
  const defaultLanguage=localStorage.getItem('default');
  const [isEnglish,setIsEnglish] =useState(defaultLanguage==='en' || defaultLanguage==null);
  useEffect(()=>{
    if(isEnglish){
      localStorage.setItem('default','en');
    }else{
      localStorage.setItem('default','fre');
    }
  },[isEnglish])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home isEnglish={isEnglish} changeLanguage={()=> setIsEnglish(isEnglish=>!isEnglish)}  />}>
          <Route index element={<LandingPage isEnglish={isEnglish} />} />
          <Route path="services" element={<Services isEnglish={isEnglish} />} />
          <Route path="promo" element={<Promo isEnglish={isEnglish} />} />
          <Route path="about-us" element={<About isEnglish={isEnglish} />} />
          <Route path="locate-us" element={<LocateUs isEnglish={isEnglish} />} />
          <Route path='services/:Id' element={<ServiceDetail isEnglish={isEnglish} />} />
          {/* <Route path="#services" element={<Services />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
