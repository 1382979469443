import React, { useEffect, useState } from "react";
import {getAboutUsPageDetails} from "../../assets/data/data.js";
import './About.css'
import AboutPageImage from '../../assets/images/AboutPage/AboutPageLarge.jpg'

const About = ({ isEnglish }) => {

  const [data,setData] = useState({});
  const [load,setLoad] = useState(false);

  useEffect(()=>{
    let result = getAboutUsPageDetails(isEnglish);
      setData(result);
      setLoad(true);
  },[isEnglish]);

  if(load){

  return(
    <div id="about" className="aboutUs mt-10 flex flex-col items-center">
      <div className="h-[40vh] w-full">
        <img src={AboutPageImage} alt="About Page" className="w-full h-full object-cover"/>
      </div>
       <div className="header uppercase font-Architects w-fit mx-auto text-2xl lg:text-4xl text-primary-text tracking-widest mt-10 lg:mt-24">
        {data.heading}
      </div>
      <div className="body w-11/12 mx-10">
        <div className="text-sm font-Akatab leading-10 px-5 text-light-black break-normal" dangerouslySetInnerHTML={{__html:data.description}}></div>
      </div>
    </div>
  );
}else{
  <div className="flex h-[80vh] w-full justify-center items-center">Loading...</div>
}
};

export default About;
