import React, { useEffect, useState } from "react";
import { getPageDetails } from "../../assets/data/data";
import AddressCard from "../AddressCard/Address";

const LocateUs = ({ isEnglish }) => {
  const [locationData,setLocationData]=useState({address:[],instaURL:"",contactNumber:""});
  useEffect(()=>{
    setLocationData(getPageDetails(isEnglish,"locateUs"));
  },[isEnglish])
  return (
    <div className="location-container w-full mt-10 flex flex-col items-center justify-evenly">
      <div className="location-map w-full border border-y-black">
       <iframe title="Locate Us" className="w-full h-[40vh] sm:h-[50vh]" allowFullScreen allow="geolocation" src="//umap.openstreetmap.fr/en/map/untitled-map_1136099?scaleControl=false&miniMap=false&scrollWheelZoom=false&zoomControl=true&editMode=disabled&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=none&captionBar=false&captionMenus=true"></iframe>
      </div>
      <div className="location-details flex md:flex-row md:items-stretch md:justify-evenly sm:mt-10 lg:mt-20 gap-y-4 w-full sm:flex-col sm:items-center">
        {
          locationData.address.map((data,index)=> { 
            return ( 
            <AddressCard key={index} data={data} />
            )
      })
        }
        <div className="location-button mt-10">
        {/* <a
          href={locationData.instaURL}
          target="_blank"
          rel="noreferrer"
          className="text-xs justify-self-end self-end text-white bg-primary-dark hover:shadow-2xl px-3 py-2 sm:px-5 sm:py-2.5 mt-2 hover:focus:outline-none"
        >
          Follow us on Instagram
        </a> */}
        </div>
      </div>
    </div>
  );
};

export default LocateUs;
