import React, { useEffect, useState } from "react";
import TestimonialCard from '../TestimonialCard/TestimonialCard';
import {getTestimonialDetails} from '../../assets/data/data'

const Testimonials = ({isEnglish}) => {
  const [load,setLoad] = useState(false);
  const [data,setData] = useState({});

  useEffect(()=>{
   setData(getTestimonialDetails(isEnglish));
   setLoad(true);
  },[isEnglish]);

  if(load){
  
  return (
    <div className="sm:mt-10 lg:mt-20">
      <p className="header font-Architects w-fit mx-auto text-2xl lg:text-4xl text-primary-text tracking-widest uppercase">
        {data.header}
      </p>
      <div className="testimonails-container w-full overflow-hidden flex flex-wrap items-center justify-evenly flex-row mt-5 lg:mt-10">
        {data.list.map((card,index)=>(
           <TestimonialCard key={index} data={card} ></TestimonialCard>
        ))}
      </div>
    </div>
  );
}else{
  <div className="h-full w-full flex items-center justify-center">Loading ...</div>
}
};

export default Testimonials;
