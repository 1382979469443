import Services from "../Services/Services";
import Testimonials from "../Testimonials/Testimonials";
import "./LandingPage.css";
import LandingPageImage from './../../assets/images/LandingPage/LandingPageImage.jpg';
import { getPageDetails } from "../../assets/data/data";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LandingPage = ({ isEnglish }) => {
  const [data, setData] = useState({});

  useEffect(() => {

    setData(getPageDetails(isEnglish, "homePage").landingPage);
  }, [isEnglish]);

  const scrollToServicePage = () => {
    const element = document.getElementById('services');
    element.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <div
        id="home"
        // h-[70vh] sm:h-[80vh]
        className="homepage relative flex flex-col lg:flex-row w-full py-5 justify-center lg:justify-evenly lg:items-center items-center  mb-5"
      >
        <div className="font-Akatab mt-2 px-4 lg:px-8 w-full lg:w-1/2 flex flex-col sm:gap-y-4 lg:gap-y-6 sm:mt-5 lg:mt-10">
          <div className="uppercase landingPage-text text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl items-start font-Architects">
            <p className="">{data?.header}</p>
          </div>
          <div className="uppercase text-xs text-primary-text 2xl:text-base">
            {data?.description}
          </div>
          <div className="flex sm:flex-col lg:flex-row gap-4 w-full">
            <div className="text-sm hover:bg-primary-dark-hover w-fit gradient hover:shadow-xl ">
              <button className="py-2 px-4 bg-primary-dark text-slate-100 uppercase" onClick={scrollToServicePage}>{data?.buttonName}</button>
            </div>
            <div className="text-sm w-fit hover:shadow-xl">
               <button className="border border-primary-dark text-primary-dark py-2 px-4 uppercase"> <Link to={'/promo'}>{data?.promoButton}</Link></button>
            </div>
          </div>
        </div>
        <div className="landing-image-container sm:w-[70vw] md:w-[40vw] mt-10 lg:w-1/3 xl:w-1/4 lg:mt-0 lg:hover:scale-105 lg:ease-linear lg:duration-300">
          <img src={LandingPageImage} className="w-full" alt="Girl with Glasses" loading="lazy" />
        </div>
      </div>
      <Services isEnglish={isEnglish} />
      <Testimonials isEnglish={isEnglish} />
    </>
  );
};

export default LandingPage;
