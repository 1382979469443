// import react from 'react'
import { IoLocationOutline } from "react-icons/io5";

const AddressCard = ({ data }) => {

    return (
        <div className="uppercase locate-card text-sm flex flex-col gap-2 sm:min-h-[250px] sm:gap-5 font-Akatab sm:w-[80vw] md:h-auto sm:mt-[5px] md:w-[30vw] bg-white border shadow-xl">
            <div className="gradient self-center border-black w-full py-7  bg-primary-dark flex items-center justify-center"><IoLocationOutline color="white" size={50} /></div>
            {
                data != null ?
                    <div className="py-2 px-3  flex flex-col gap-2">
                        <p className=""><strong>{data?.street}</strong></p>
                        <p>{data?.city}</p>
                        <p>{data?.pincodeAndCountry}</p>
                        <p>{data.contactNumber !== undefined && data.contactNumber !== "" ? `Contact Number : ${data.contactNumber}` : ""} </p>
                    </div>
                    :
                    <div className=" uppercase py-2 px-3  flex flex-col items-center justify-center gap-2">
                        <strong>Coming Soon to Vaudreuil-Dorion!</strong> Your New Beauty Destination
                    </div>
            }
        </div>
    );
}

export default AddressCard;