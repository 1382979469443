import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaInstagramSquare } from "react-icons/fa";
import { getPageDetails } from "../../assets/data/data";
// import { getPageDetails } from "../../assets/data/data";

const Footer = ({ isEnglish, changeLanguage }) => {
  const [footer,setFooter] = useState({});
  const [load,setLoad] = useState(false);
  useEffect(()=>{
    setFooter(getPageDetails(isEnglish,"footer"));
    setLoad(true);
  },[isEnglish]);
  const handleChange = (evt) => {
    if (
      (evt.target.value === "en" && !isEnglish) ||
      (evt.target.value === "fre" && isEnglish)
    ) {
      changeLanguage();
    }
  };

  if(load){
  return (
    <div className="gradiant uppercase px-2 footer flex flex-col items-center justify-center sm:mt-12 lg:mt-20 min-h-[30vh] bg-primary-dark text-primary-off-white  font-Akatab w-full">
      <div className="text-xs sm:text-sm mt-10 h-4/5 w-full flex justify-evenly">
        <div className="footer-language">
          <label htmlFor="languageSelected">
            <strong>{footer.language}</strong>
          </label>
          <select
            onChange={handleChange}
            defaultValue={isEnglish ? "en" : "fre"}
            id="languageSelected"
            className="mt-10 block text-slate-900 py-2 px-4 w-full bg-white border-b-2 appearance-none focus:outline-none focus:ring-0"
          >
            <option value="en">English</option>
            <option value="fre">French</option>
          </select>
        </div>
        <div className="website-links ">
          <p className="mb-10">
            <strong>{footer.links}</strong>
          </p>
          <p>
            <u>
              <Link to="/about-us">{footer.aboutUs}</Link>
            </u>
          </p>
          <p>
            <u>
              <Link to="/locate-us">{footer.locateUs}</Link>
            </u>
          </p>
        </div>
        <div className="branding-links">
          <p className="max-w-12 mb-5 sm:max-w-20">
            <strong>{footer.contactUs}</strong>
          </p>
          <p>{footer.contactNumber}</p>
          <a
            href={footer.instaURL}
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagramSquare size={40}/>
          </a>
        </div>
      </div>
      <p className="mt-[10vh] mb-5 justify-end font-Aldrich text-lg">
        HK BEAUTÉ <span className="text-sm">Et spa</span>
      </p>
    </div>
  );
}else{
  <div>loading</div>
}
};

export default Footer;
