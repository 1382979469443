import React, { useState,useEffect } from "react";
import LOGO from '../../assets/images/Logo.png';
import { Link } from "react-router-dom";

const Header = ({data}) => {
    const [labels,setLabels] = useState({});

    useEffect(()=>{
      setLabels(data);
    },[data]);


    return(
        <div className="sm:hidden md:flex flex-col w-full items-center justify-center">
        <div className="w-full flex items-center justify-center relative pt-5 md:pt-7 pb-3 md:pb-5 ">
          <div className="logo-container h-10 md:h-12 lg:h-20 absolute left-4 md:left-5 lg:left-10">
            <img className="h-full w-auto" src={LOGO} alt="Brand Logo" />
          </div>
          <div className="font-Aldrich header-brand relative left-3 text-2xl md:text-4xl lg:text-5xl">
            <Link to={'/'}>HK BEAUTÉ <span className="text-xs">Et spa</span></Link>
          </div>
        </div>
        
        <div className="header-links font-Akatab text-sm lg:text-lg uppercase flex flex-row w-[90vw] max-w-[1000px] justify-between">
          <span className="hover:border-black  border-b-2 border-transparent"><Link to="/">{labels.welcome}</Link></span>
          <span className="hover:border-black  border-b-2 border-transparent"><Link to="/services">{labels.ourServices}</Link></span>
          <span className="hover:border-black  border-b-2 border-transparent"><Link to="/promo">{labels.promo}</Link></span>
          <span className="hover:border-black  border-b-2 border-transparent"><Link to="/about-us">{labels.aboutUs}</Link></span>
          <span className="hover:border-black  border-b-2 border-transparent"><Link to="/locate-us">{labels.locateUs}</Link></span>
        </div>
        </div>
     )
}

export default Header;